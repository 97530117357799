<template>
    <div class="list">
        <van-list v-model="_loading" :finished="_finished" finished-text="" @load="onLoad">
            <div class="list-body">
                <slot></slot>
            </div>
            <div class="list-footer">
                <div class="list-finished" v-if="_finished == true && _isEmpty == false">
                    <div class="list-finished-text">已经到底啦</div>
                </div>
                <div class="list-empty" v-if="_loading == false && _isEmpty == true">
                    <van-empty :image="require('@/assets/img/empty.png')" description="这里是空的哦" />
                </div>
            </div>
        </van-list>
    </div>
</template>

<script>
export default {
    data() {
        return {
            _isEmpty: false,
            _loading: false,
            _finished: false
        };
    },
    props: {
        isEmpty: {
            default: false
        },
        loading: {
            default: false
        },
        finished: {
            default: false
        }
    },
    created() {
        this._isEmpty = this.isEmpty;
        this._loading = this.loading;
        this._finished = this.finished;
    },
    watch: {
        isEmpty() {
            this._isEmpty = this.isEmpty;
        },
        loading() {
            this._loading = this.loading;
        },
        finished() {
            this._finished = this.finished;
        }
    },
    methods: {
        onLoad() {
            this.$emit('onLoad');
        }
    }
};
</script>

<style lang="less" scoped>
.list {

    .list-finished {
        padding: 10px 0;
        text-align: center;

        .list-finished-text {
            font-size: 14px;
            color: #969799;
            display: inline-block;
            padding: 0 20px;
        }
    }

    .list-empty {
        /deep/.van-empty__image {
            width: 100px;
            height: 100px;
        }
    }
}
</style>

<template>
	<div :class="['w-title', className('w-title-')]">
		<div class="text">{{ title }}</div>
		<div class="more" v-if="more" @click="onClick">{{ moreText }}</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			default: ''
		},
		more: {
			default: false
		},
		moreText: {
			default: '查看更多'
		}
	},
	inject: ['className'],
	methods: {
		onClick() {
			this.$emit('click');
		}
	}
};
</script>

<style lang="less" scoped>
.w-title {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.text {
		padding-left: 8px;
		position: relative;
		font-size: 18px;
		font-weight: bold;
		line-height: 30px;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			width: 3px;
			height: 20px;
			transform: translateY(-50%);
			border-radius: 100px;
		}
	}

	.more {
		font-size: 14px;
		color: #666;
		padding-right: 16px;
		background-repeat: no-repeat;
		background-position: right center;
		background-size: 14px;
	}
}

.w-title-city {
	.text {
		color: #3377ff;

		&:after {
			background-color: #3377ff;
		}
	}

	.more {
		background-image: url('~@/assets/img/w_title_icon_city.png');
	}
}

.w-title-district { 
	.text {
		color: #ff6969;

		&:after {
			background-color: #ff6969;
		}
	}

	.more {
		background-image: url('~@/assets/img/w_title_icon_district.png');
	}
}
</style>
